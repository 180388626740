import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useFetchers,
  useRouteError,
} from "@remix-run/react";
import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import uiGlobalStylesheet from "@bigboi/ui/globals.css?url";
import sonnerStyles from "~/styles/sonner.css?url";
import { toast, Toaster } from "sonner";
import { RootErrorBoundary } from "./components/error-boundary/index.js";
import { type ActionErrorResponse, isActionError } from "./utils/response.js";
import { useEffect, useRef } from "react";
import { sellers, users } from "@bigboi/database/schema";
import { useRootTheme } from "./hooks/theme.js";
import {
  CircleAlert,
  CircleCheckBig,
  CircleXIcon,
  InfoIcon,
} from "lucide-react";
import {
  getSellerOrThrow,
  getUserFromSessionOrThrow,
} from "./utils/auth/core.server.js";
import { SessionRepository } from "./repositories/sessions.server.js";
import { UserRepository } from "./repositories/users.server.js";
import { SellerRepository } from "./repositories/sellers.server.js";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: uiGlobalStylesheet },
  { rel: "stylesheet", href: sonnerStyles },
];

export const meta: MetaFunction = () => [
  {
    charset: "utf-8",
    title: "New Remix App",
    viewport: "width=device-width,initial-scale=1",
  },
];

export const DEFAULT_THEME = "dark";

export const loader = async (args: LoaderFunctionArgs) => {
  try {
    const user = await getUserFromSessionOrThrow({
      sessionRepo: new SessionRepository(),
      cookieHeader: args.request.headers.get("Cookie"),
      userRepo: new UserRepository(),
      projection: { id: users.id },
      thrower: () => {
        throw new Error();
      },
    });

    const seller = await getSellerOrThrow({
      userId: user.id,
      projection: { activeTheme: sellers.activeTheme },
      sellerRepo: new SellerRepository(),
      thrower: () => {
        throw new Error();
      },
    });

    return { activeTheme: seller.activeTheme ?? DEFAULT_THEME };
  } catch (e) {
    // If this throws, it's because the user isn't signed in.
    return { activeTheme: DEFAULT_THEME };
  }
};

function useRootFetchersActionErrorToast() {
  const actionErrors = useFetchers()
    .filter((fetcher) => {
      return isActionError(fetcher.data);
    })
    .map((fetcher) => fetcher.data.error as ActionErrorResponse["error"]);

  const displayedErrors = useRef<Set<number>>(new Set());

  useEffect(() => {
    actionErrors.forEach((error) => {
      displayedErrors.current.has(error.timestamp);
      if (!displayedErrors.current.has(error.timestamp)) {
        toast.error(error.message);
        displayedErrors.current.add(error.timestamp);
      }
    });
  }, [actionErrors]);
}

export function Layout({ children }: { children: React.ReactNode }) {
  useRootFetchersActionErrorToast();
  const theme = useRootTheme();

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className={theme}>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  return (
    <>
      <Toaster
        toastOptions={{
          classNames: {
            toast: "bg-base-200 border-border space-x-2",
            title: "text-foreground",
            description: "text-foreground-muted",
            actionButton: "bg-primary",
            cancelButton: "bg-accent",
            closeButton: "bg-accent",
          },
        }}
        icons={{
          success: <CircleCheckBig className="text-success" />,
          info: <InfoIcon className="text-info" />,
          warning: <CircleAlert className="text-warning" />,
          error: <CircleXIcon className="text-destructive" />,
        }}
      />
      <Outlet />
    </>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return (
    <div className="h-screen">
      <RootErrorBoundary />
    </div>
  );
}

export default withSentry(App);
